$body-bg:$background;

$horizontal-gutter:40px;

*{
    padding:0;
    margin:0;
    box-sizing:border-box;
}

body{
    background:$body-bg;
    color:#fff;
    font-family: $fontBody;
    font-size: 17px;
    width:100vw;
    position:relative;
    overflow-x: hidden;
}

h1,h2,h3,h4{
    font-family: $fontHeadings;
    font-weight:normal;
}

.splatter-top{
    position:absolute;
    top:0;
    right:0;
    mix-blend-mode: hard-light;
    width: 50%;
    opacity:0.9;
}

.splatter-bottom{
    position:absolute;
    bottom:0;
    left:0;
    mix-blend-mode: hard-light;
    width: 50%;
    opacity:0.9;
}

header{
    padding:20px $horizontal-gutter;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
    z-index: 1;
    position:sticky;
    top:0;
    z-index: 11;
    &:before{
        content:'';
        display:block;
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        transition:all 300ms linear;
        background: linear-gradient(180deg,$body-bg, rgba($body-bg,0));
        opacity:0;
    }
    &.scrolled{
        &:before{
            opacity:1;
        }
    }
    .logo{
        display:block;
        transition:all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        position:relative;
        &:hover{
            transform: rotate(-5deg) scale(1.1);
        }
        img{
            width: 160px;
        }
    }
    nav{
        position: relative;
        .open-menu,.close-menu {
            display: none;
        }
            @media all and (max-width:768px) {
                .open-menu {
                    display: block;
                    z-index:2;
                    position:relative;
                }
                .close-menu {
                    display: none;
                    z-index:2;
                    position:relative;
                }
                &.open {
                    ul {
                        left:0;
                    }
                    .open-menu {
                        display: none;
                    }
                    .close-menu {
                        display: block;
                    }
                }
            }
        

            ul {
            list-style-type:none;
            display:flex;
            gap:100px;
            align-items:center;
            @media all and (max-width:820px){
                gap:30px !important;
            }
            @media all and (max-width:768px){
                align-items: center;
                background: #000;
                bottom: 0;
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: center;
                left: 100vw;
                width:100vw;
                position: fixed;
                top: 0;
                width: 100vw;
                transition:all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
            }
            a{
                display:block;
                font-family: $fontHeadings;
                color:#999999;
                text-decoration:none;
                font-size:20px;
                transition:all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
                &.active,&:hover{
                    color:#fff;
                }
            }
        }
    }
}

.flash-button{
    background: linear-gradient($body-bg, $body-bg) padding-box,
    linear-gradient(135deg, $secondary, $primary) border-box;
    border-radius:10px;
    padding:15px 20px;
    border: 4px solid transparent;
    color: #fff;
    font-family: $fontHeadings;
    font-size: 20px;
    transition:all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    cursor:pointer;
    &:hover{
        transform: rotate(-5deg) scale(1.1);
    }
}

main{
    .content{
        padding-left:$horizontal-gutter;
        padding-right:$horizontal-gutter;
        padding-bottom:$horizontal-gutter;
    }
    .content p a{
        color: #fff;
        text-decoration: none
    }
    position:relative;
    z-index: 1;
    width:100vw;
    overflow:hidden;
    padding-bottom:$horizontal-gutter;
    h1{
        font-size: 70px;
        line-height: 85px;
        margin-bottom: 50px;
        @media all and (max-width:768px){
            font-size:40px;
            line-height:50px;
            margin-bottom:10px;
        }

        span{
            font-size:90px;
            @media all and (max-width:768px){
                font-size:50px;
            }
            display:block;
            text-transform:uppercase;
            -webkit-text-stroke: 7px transparent;
            background: linear-gradient(270deg,$primary,$primary);
            -webkit-background-clip: text;
            color: #fff;
        }
    }
    h2,h3{
        font-family: $fontBody;
        font-style:italic;
    }
    h2{
        font-size:24px;
        font-weight:700;
    }
    h3{
        font-size:20px;
        @media all and (max-width:768px){
            margin-bottom:30px;
        }
    }
}

#signup{
        h1{
            font-size: 40px;
            line-height: 55px;
            margin-bottom: 0;
            @media all and (max-width:768px){
                font-size:40px;
                line-height:50px;
                margin-bottom:10px;
            }
        }
        h2{
            font-size:70px;
            font-style:normal;
            font-family: $fontHeadings;
            @media all and (max-width:768px){
                font-size:50px;
            }
            span{
                font-size:80px;
                @media all and (max-width:768px){
                    font-size:50px;
                }
                text-transform:uppercase;
                -webkit-text-stroke: 7px transparent;
                background: linear-gradient(270deg,$primary,$primary);
                -webkit-background-clip: text;
                color: #fff;
            }
        }
    
    p{
        width:80%;
        line-height:30px;
    }
    .form{
        width:60%;
        margin-top:100px;
        margin-bottom:100px;
        transform: skew(0, -7deg);
        a{
            color:#fff;
        }
        @media all and (max-width:1180px) {
            transform:none;
            width:100%;
            margin-top:30px;
        }
        &.backgrounded{
            border-radius:10px;
            background:#f00;
            box-shadow: 0 0 16px 0 rgba(0,0,0,.7);
            padding:15px;
            margin-top:80px;
            background: linear-gradient(270deg,$secondary,$primary);
        }
        input[type="text"],textarea,select{
            display:block;
            background:#fff;
            border:none;
            padding:15px;
            box-shadow: 0 0 16px 0 rgba(0,0,0,.7);
            width:100%;
            border-radius:10px;
            margin-bottom:20px;
            font-family: $fontBody;
            font-size:18px;
            border:5px solid #fff;
            transition:all .3s linear;
            &:hover{
                border:5px solid $primary;
                cursor:pointer;
            }
            &:focus{
                outline:0;
                border:5px solid $secondary;
            }
        }
        input[type="text"] + div,
        textarea + div,
        select + div{
            position: relative;
            top: -12px;
        }
        textarea{
            height:150px;
        }
        button{
            display:block;
            width:100%;
            text-transform:uppercase;
            font-size:40px;
        }
    }
}





:root {
    --color-text: navy;
    --color-bg: papayawhip;
    --color-bg-accent: #ecdcc0;
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
    --gap: calc(var(--size) / 14);
    --duration: 60s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
  }

.marquee {
    display: flex;
    gap: var(--gap);
    user-select: none;
    transform: skew(7deg, -7deg);
    margin-bottom: 40px;
    width:100vw;
  }
  
  .marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .marquee__group {
      animation-play-state: paused;
    }
  }
  
  
  .marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: -3s;
  }
  
  @keyframes scroll-x {
    from {
      transform: translateX(var(--scroll-start));
    }
    to {
      transform: translateX(var(--scroll-end));
    }
  }
  
  @keyframes scroll-y {
    from {
      transform: translateY(var(--scroll-start));
    }
    to {
      transform: translateY(var(--scroll-end));
    }
  }

  .marquee li{
    align-items: center;
    background-position: center center;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 170px;
    justify-content: center;
    list-style-type: none;
    text-align: center;
    width: 330px;
    box-shadow: 0 0 16px 0 rgba(0,0,0,.7);
    backdrop-filter: blur(5px);
    &.text-games{
        background-color:rgba($primary, 0.6);
    }
    &.text-movies{
        background-color:rgba($secondary, 0.6);
    }
    h2{
        font-family: $fontHeadings;
        font-style: normal;
        text-transform: uppercase;
        font-size: 70px;
        font-weight: normal;
        letter-spacing: 2px;
        text-align: center;
    }
    p{
        font-style:italic;
    }
  }

  #pricing{
    position:relative;
    margin-top:50px;
    @media all and (max-width:768px){
        margin-top:60px;
    }
    padding-left:$horizontal-gutter;
    padding-right:$horizontal-gutter;
    h2{
        font-size: 70px;
        margin-bottom:30px;
        @media all and (max-width:768px){
            font-size:40px;
            line-height:50px;
            margin-bottom:10px;
        }
        span{
            font-size:80px;
            @media all and (max-width:768px){
                font-size:50px;
            }
            text-transform:uppercase;
            -webkit-text-stroke: 7px transparent;
            background: linear-gradient(270deg,$secondary,$secondary);
            -webkit-background-clip: text;
            color: #fff;
        }
    }
    h3{
        font-family: $fontBody;
        font-style:italic;
        font-size:24px;
        margin-bottom:10px;
    }
    p{
        color:#fff;
        font-size:15px;
        width:550px;
        @media all and (max-width:768px){
            width:auto;
        }
    }
    .bundles{
        display: flex;
        justify-content: center;
        gap:50px;
        margin-top:100px;
        @media all and (max-width:1180px){
            gap:0;
            justify-content: space-between;
        }
        @media all and (max-width:820px){
            margin-top:190px;
        }
        @media all and (max-width:768px){
            display:block;
        }
        > div{
            padding: 15px;
            border-radius:10px;
            display:flex;
            flex-direction:column;
            width:20%;
            text-align: center;
            @media all and (max-width:1180px){
                width:30%;         
            }
            @media all and (max-width:768px){
                width:100%;
                margin-bottom:50px;
            }
            &.movies{
                background:$primary;
                .price,a{
                    color:$primary
                }
                h3{
                    background:linear-gradient(to left, $primary, $primary);
                    -webkit-background-clip: text;
                    -webkit-text-stroke: 10px transparent;
                    color: #fff;
                }
            }
            &.bundle{
                background:linear-gradient(to left, $secondary, $primary);
                transform:translateY(-70px);
                @media all and (max-width:768px){
                transform:none;
                background:linear-gradient(to top, $secondary, $primary);
                }
                a{
                    color: #fff;
                }
                .price,a{
                    color: #fff;
                    background:linear-gradient(to left, $secondary, $primary);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                h3{
                    background:linear-gradient(to left, $secondary, $primary);
                    -webkit-background-clip: text;
                    -webkit-text-stroke: 10px transparent;
                    color: #fff;
                }
            }
            &.games{
                background:$secondary;
                transform:translateY(-140px);
                @media all and (max-width:768px){
                    transform:none;
                }
                .price,a{
                    color:$secondary
                }
                h3{
                    background:linear-gradient(to left, $secondary, $secondary);
                    -webkit-background-clip: text;
                    -webkit-text-stroke: 10px transparent;
                    color: #fff;
                }
            }
            .content{
                background:#fff;
                border-radius:10px;
                flex:1;
                color:#171717;
                box-shadow: 0 0 16px 0 rgba(0,0,0,.7);
                margin-bottom:15px;
                padding-bottom:30px;
                h3{
                    font-family: $fontHeadings;
                    font-size:70px;
                    font-style:initial;
                    text-transform:uppercase;
                    margin-top: -50px;
                    color:#fff;
                    @media all and (max-width:820px){
                        margin-top:-60px;
                        font-size: 50px;
                    }
                }
                .note{
                    font-size:12px;
                    color:#868686;
                }
                .price{
                    font-size:80px;
                    font-family: $fontHeadings;
                    margin:15px 0;
                    span{
                        font-size:30px;
                        @media all and (max-width:820px){
                            font-size:20px;
                        }
                    }
                    @media all and (max-width:820px){
                        font-size:40px;
                    }
                }
                ul{
                    list-style-type:none;
                    text-align: center;
                    font-style:italic;
                    li{
                        margin-bottom:10px;
                    }
                }
            }
            .button-wrapper{
                background:#fff;
                border-radius:10px;
                transition:all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
                &:hover{
                    transform: rotate(-5deg) scale(1.1);
                }
            }
            a{
                display:block;
                font-family: $fontHeadings;
                text-transform:uppercase;
                box-shadow: 0 0 16px 0 rgba(0,0,0,.7);
                padding:15px 0;
                font-size:40px;
                text-decoration:none;
            }
        }
    }
  }

  footer{
    background-image: url(../images/footer-bg.svg);
    background-repeat:no-repeat;
    background-position:center top;
    position:relative;
    background-size:cover;
    margin-left:$horizontal-gutter;
    margin-right:$horizontal-gutter;
    @media all and (max-width:1180px){
        margin-left:0;
        margin-right:0;
    }
    margin-top:100px;
    padding-left:$horizontal-gutter;
    padding-right:$horizontal-gutter;
    padding-bottom:$horizontal-gutter;
    color:#868686;
    opacity: 0.9;
    @media all and (max-width:768px){
     margin-left:0;
     margin-right:0;           
    }
    .flash-button{
        text-transform:uppercase;
        font-size:30px;
        padding-left:50px;
        padding-right:50px;
        display:inline-block;
        transition:all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        @media all and (max-width:768px){
            display:block;
            padding-left:5px;
            padding-right:5px;
            text-align:center;
            margin-bottom:100px;   
        }
        &:hover{
            transform: rotate(-5deg) scale(1.1);
        }
    }
    a{
        color:#868686;
        text-decoration:none;
        transition:all .3s linear;
        &:hover{
            opacity:0.6;
        }
    }
    .logo{
        display:block;
        width:150px;
        margin:auto;
    }
    .columns{
        display:flex;
        justify-content: space-between;
        margin-top:100px;
        @media all and (max-width:768px){
         display:block;       
        }
        > div{
            width:33.333333%;
            @media all and (max-width:768px){
             width:auto;   
            }
            h4{
                font-size:25px;
                color:#fff;
                margin-bottom:10px;
                @media all and (max-width:768px){
                margin-top:40px;
                }
            }
            font-size:15px;
        }
    }
    .copyright{
        font-size:14px;
        width:80%;
        @media all and (max-width:768px){
         width:auto;   
        }
    }
}




.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5)
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
    @media all and (max-width:768px) {
        width:100%;
    }
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.site-TVGame{
    #signup .form{
        transform:none;
        &.backgrounded{
            padding:0;
            background:transparent;
        }
    }
    .marquee svg {
        display:none;
    }
    main h1 span,
    #pricing h2 span,
    #signup h2 span {
        -webkit-text-stroke: 9px transparent;
        padding-left:3px;
        padding-right:3px;
        @media all and (max-width:820px){
            font-size:41px;
            -webkit-text-stroke: 7px transparent;
        }
    }
    .splatter-top,
    .splatter-bottom{
        display:none;
    }
    header nav ul a{
        color:#fff;
    }
    header nav ul a.active{
        color:$primary;
    }
    .marquee li.text-movies{
        background:$secondary
    }
    .marquee li.text-games{
        background:$primary
    }
    main h2, main h3{
        font-style:normal;
    }
    #pricing .bundles>div{
        .content{
            background:transparent;
            box-shadow:none;
            color:#fff;
        }
        .price{
            color:#fff;
        }
        .note{
            color:#fff;
        }
        &.bundle {
            background: #06192f;
            .price{
                color:#fff;
                background:transparent;
                -webkit-text-fill-color:initial;
            }
            a{
                color:#06192f;
                background:transparent;
                -webkit-text-fill-color:initial;
            }
            h3{
                background: linear-gradient(270deg,#06192f,#06192f);
                -webkit-background-clip: text;
            }
        }
    }
    footer{
        background-image: none;
        background-color: #06192f;
        opacity: 1;
        border-radius:20px 20px 0 0;
        .logo{
            position: relative;
            top: -120px;
            margin-bottom: -230px;
            @media all and (max-width:820px){
                top: -80px;
                margin-bottom: -150px;
            }
        }
    }
}


.site-FunZones{
    background:darken($primary,10%);
    main{
        .content{
            text-align: center;
            h1{
                span{
                    font-weight:700;
                }
                font-weight:400;
            }
        }
    }
    .marquee {
        transform:none;
        margin-top:30px;
        svg{
            display:none;
        }
    }
    main h1 span,
    #pricing h2 span,
    #signup h2 span {
        background:transparent;
        color:$secondary;
    }
    .splatter-top,
    .splatter-bottom{
        display:none;
    }
    #pricing p{
        font-size: 18px;
        width: 990px;
    }
    header nav ul{
        gap: 70px;
        a,
        a.flash-button{
            color:#fff;
            font-size:25px;
        }
        a.active{
            color:$secondary;
        }
    }
    .marquee li{
        filter: grayscale(1);
        h2{
            font-weight:700;
        }
        &.text-movies{
            background:$secondary;
            filter: grayscale(0);
        }
        &.text-games{
            background:$primary;
            filter: grayscale(0);
        }
    }
    main h2, main h3{
        font-style:normal;
    }
    #pricing{
        h2{
            font-weight:400;
            text-align: center;
            span{
                font-weight:700;
            }
        }
        p{
            text-align: center;
            margin-left:auto;
            margin-right:auto;
            @media all and (max-width:820px){
                width:auto;
            }
        }
        .bundles{
            margin-bottom:250px;
            margin-top:0;
        }
        .bundles>div{
            transform: none;
            background:transparent;
            border:5px solid $secondary;
            margin-top:100px;
            .content{
                background:transparent;
                box-shadow:none;
                color:#fff;
            }
            .price{
                color:#fff;
            }
            .note{
                color:#fff;
            }
            .price{
                color:#fff;
                background:transparent;
                -webkit-text-fill-color:initial;
                font-weight:700;
            }
            .button-wrapper{
                margin-bottom: -60px;
            }
            a{
                color:#06192f;
                background:transparent;
                -webkit-text-fill-color:initial;
            }
            h3{
                background:darken($primary,10%);
                -webkit-background-clip: initial;
                font-weight:700;
                display:inline-block;
                padding-left:10px;
                padding-right:10px;
            }
        }
    }
    footer{
        border-radius: 20px 20px 0 0;
        opacity: 1;
        background: transparent;
        border: 5px solid $secondary;
        border-bottom:none;
        .logo{
            margin-bottom: -130px;
            position: relative;
            top: -65px;
            background:darken($primary,10%);
        }
    }
    .flash-button{
        background:$secondary;
        border-radius:0;
    }
    #signup .form{
        transform:none;
    }
    #signup .form input, #signup .form textarea{
        border-radius:0;
    }
    #signup p{
        margin:auto;
        @media all and (max-width:820px){
            width:auto;
        }
    }
}

.site-FunVision{
    background:darken($primary,50%);
    #home{
        .content{
            margin-bottom:100px;
            margin-top:100px;
            text-align: center;
        }
    }
    .marquee{
        transform:none;
        position:relative;

        li{
            filter: grayscale(1);
            box-shadow:none;
            opacity:0.6;
            &.text-games,
            &.text-movies{
                filter: none;
                opacity:1;
            }
            p{
                font-style:normal;
            }
        }

        svg{
            display:none;
        }

        &:before{
            background: linear-gradient(-270deg,darken($primary,50%),transparent);
            content: "";
            width: 200px;
            @media all and (max-width:820px){
                width:100px;
            }
            height: 100%;
            position: absolute;
            left:0;
            z-index:1;
        }
        &:after{
            background: linear-gradient(-270deg,transparent,darken($primary,50%));
            content: "";
            width: 200px;
            @media all and (max-width:820px){
                width:100px;
            }
            height: 100%;
            position: absolute;
            right:0;
            z-index:1;
        }
    }
    main h1 span,
    #pricing h2 span,
    #signup h2 span {
        background:transparent;
        color:$secondary;
    }
    .splatter-top,
    .splatter-bottom{
        display:none;
    }
    header nav ul{
        gap: 70px;
        a,
        a.flash-button{
            color:#fff;
            font-size:25px;
        }
        a.active{
            color:$secondary;
        }
    }
    .marquee li{
        border-radius:0;
        &.text-movies{
            background:$secondary
        }
        &.text-games{
            background:$primary
        }
    }
    main h2, main h3{
        font-style:normal;
    }

    #pricing{
        margin-top:0;
        .bundles{
            margin-bottom: 200px;
            @media all and (max-width:820px){
                margin-top:0;
            }
            >div{
                border-radius:0;
                @media all and (max-width:820px){
                    margin-top:100px;
                }
                .content{
                    background:transparent;
                    box-shadow:none;
                    color:#fff;
                }
                .price{
                    color:#fff;
                }
                .note{
                    color:#fff;
                }
                .button-wrapper{
                    border-radius:0;
                }
                &.movies{
                    transform:translateY(-140px);
                    @media all and (max-width:820px){
                        transform:none;
                    }
                }
                &.bundle {
                    background: #06192f;
                    transform:translateY(-70px);
                    @media all and (max-width:820px){
                        transform:none;
                    }
                    .price{
                        color:#fff;
                        background:transparent;
                        -webkit-text-fill-color:initial;
                    }
                    a{
                        color:#06192f;
                        background:transparent;
                        -webkit-text-fill-color:initial;
                    }
                    h3{
                        background: linear-gradient(270deg,#06192f,#06192f);
                        -webkit-background-clip: text;
                    }
                }
                &.games{
                    transform:none;
                }
            }
        }
        h2{
            text-align:right;
            @media all and (max-width:820px){
                text-align:left;
            }
        }
        p{
            font-size: 18px;
            width: 690px;
            margin-left: auto;
            text-align: right;
            @media all and (max-width:820px){
                width:auto;
                text-align:left;
            }
        }
    }



    footer{
        background-image: none;
        background-color: #06192f;
        opacity: 1;
        border-radius:20px 20px 0 0;
        .logo{
            margin-bottom: -70px;
            position: relative;
            top: -15px;
        }
    }
    .flash-button{
        background:$secondary;
        border-radius:0;
    }
    #signup .form{
        transform:none;
        input, textarea{
            border-radius:0;
        }
    }
}

.site-PlayStream{
    background:darken($primary,50%);
    .marquee svg {
        &:nth-child(even) {
            path{
                fill: #fff;
            }
        }
        &:nth-child(odd) {
            path{
                fill: $primary;
            }
        }
        
    }
    main h1 span,
    #pricing h2 span,
    #signup h2 span {
        background:transparent;
        color:$secondary;
    }
    .splatter-top,
    .splatter-bottom{
        display:none;
    }
    #pricing p{
        font-size: 18px;
        width: 690px;
    }
    header nav ul{
        gap: 70px;
        a,
        a.flash-button{
            color:#fff;
            font-size:25px;
        }
        a.active{
            color:$secondary;
        }
    }
    .marquee li{
        border-radius:0;
        &.text-movies{
            background:$secondary
        }
        &.text-games{
            background:$primary
        }
    }
    main h2, main h3{
        font-style:normal;
    }
    #pricing .bundles>div{
        border-radius:0;
        .content{
            background:transparent;
            box-shadow:none;
            color:#fff;
        }
        .price{
            color:#fff;
        }
        .note{
            color:#fff;
        }
        .button-wrapper{
            border-radius:0;
        }
        &.bundle {
            background: #06192f;
            .price{
                color:#fff;
                background:transparent;
                -webkit-text-fill-color:initial;
            }
            a{
                color:#06192f;
                background:transparent;
                -webkit-text-fill-color:initial;
            }
            h3{
                background: linear-gradient(270deg,#06192f,#06192f);
                -webkit-background-clip: text;
            }
        }
    }
    footer{
        background-image: none;
        background-color: #06192f;
        opacity: 1;
        border-radius:20px 20px 0 0;
        .logo{
            margin-bottom: -70px;
            position: relative;
            top: -15px;
        }
    }
    .flash-button{
        background:$secondary;
        border-radius:0;
    }
    #signup .form input, #signup .form textarea{
        border-radius:0;
    }
    .marquee{
        margin-bottom:0;
        gap:0;
    }
    .marquee__group{
        gap:0;
    }
}

.form-check{
    a{color:$secondary !important;}
}

#contactForm{
    transition:all 200ms linear;
    position:relative;
    .icon{
        display:none;
        position:absolute;
        width:128px;
        height:43px;
        left:50%;
        top:50%;
        margin-left:-64px;
        margin-top:-21px;
    }
    &.loading{
        opacity:0.3;
        .icon{
            display:block;
        }
    }
}

