* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #010108;
  color: #fff;
  font-family: "Figtree", sans-serif;
  font-size: 17px;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
}

h1, h2, h3, h4 {
  font-family: "Bebas Neue", cursive;
  font-weight: normal;
}

.splatter-top {
  position: absolute;
  top: 0;
  right: 0;
  mix-blend-mode: hard-light;
  width: 50%;
  opacity: 0.9;
}

.splatter-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  mix-blend-mode: hard-light;
  width: 50%;
  opacity: 0.9;
}

header {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  position: sticky;
  top: 0;
  z-index: 11;
}
header:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 300ms linear;
  background: linear-gradient(180deg, #010108, rgba(1, 1, 8, 0));
  opacity: 0;
}
header.scrolled:before {
  opacity: 1;
}
header .logo {
  display: block;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  position: relative;
}
header .logo:hover {
  transform: rotate(-5deg) scale(1.1);
}
header .logo img {
  width: 160px;
}
header nav {
  position: relative;
}
header nav .open-menu, header nav .close-menu {
  display: none;
}
@media all and (max-width: 768px) {
  header nav .open-menu {
    display: block;
    z-index: 2;
    position: relative;
  }
  header nav .close-menu {
    display: none;
    z-index: 2;
    position: relative;
  }
  header nav.open ul {
    left: 0;
  }
  header nav.open .open-menu {
    display: none;
  }
  header nav.open .close-menu {
    display: block;
  }
}
header nav ul {
  list-style-type: none;
  display: flex;
  gap: 100px;
  align-items: center;
}
@media all and (max-width: 820px) {
  header nav ul {
    gap: 30px !important;
  }
}
@media all and (max-width: 768px) {
  header nav ul {
    align-items: center;
    background: #000;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    left: 100vw;
    width: 100vw;
    position: fixed;
    top: 0;
    width: 100vw;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
header nav ul a {
  display: block;
  font-family: "Bebas Neue", cursive;
  color: #999999;
  text-decoration: none;
  font-size: 20px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
header nav ul a.active, header nav ul a:hover {
  color: #fff;
}

.flash-button {
  background: linear-gradient(#010108, #010108) padding-box, linear-gradient(135deg, #FF6400, #AF4BFF) border-box;
  border-radius: 10px;
  padding: 15px 20px;
  border: 4px solid transparent;
  color: #fff;
  font-family: "Bebas Neue", cursive;
  font-size: 20px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  cursor: pointer;
}
.flash-button:hover {
  transform: rotate(-5deg) scale(1.1);
}

main {
  position: relative;
  z-index: 1;
  width: 100vw;
  overflow: hidden;
  padding-bottom: 40px;
}
main .content {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}
main .content p a {
  color: #fff;
  text-decoration: none;
}
main h1 {
  font-size: 70px;
  line-height: 85px;
  margin-bottom: 50px;
}
@media all and (max-width: 768px) {
  main h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  }
}
main h1 span {
  font-size: 90px;
  display: block;
  text-transform: uppercase;
  -webkit-text-stroke: 7px transparent;
  background: linear-gradient(270deg, #AF4BFF, #AF4BFF);
  -webkit-background-clip: text;
  color: #fff;
}
@media all and (max-width: 768px) {
  main h1 span {
    font-size: 50px;
  }
}
main h2, main h3 {
  font-family: "Figtree", sans-serif;
  font-style: italic;
}
main h2 {
  font-size: 24px;
  font-weight: 700;
}
main h3 {
  font-size: 20px;
}
@media all and (max-width: 768px) {
  main h3 {
    margin-bottom: 30px;
  }
}

#signup h1 {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 0;
}
@media all and (max-width: 768px) {
  #signup h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  }
}
#signup h2 {
  font-size: 70px;
  font-style: normal;
  font-family: "Bebas Neue", cursive;
}
@media all and (max-width: 768px) {
  #signup h2 {
    font-size: 50px;
  }
}
#signup h2 span {
  font-size: 80px;
  text-transform: uppercase;
  -webkit-text-stroke: 7px transparent;
  background: linear-gradient(270deg, #AF4BFF, #AF4BFF);
  -webkit-background-clip: text;
  color: #fff;
}
@media all and (max-width: 768px) {
  #signup h2 span {
    font-size: 50px;
  }
}
#signup p {
  width: 80%;
  line-height: 30px;
}
#signup .form {
  width: 60%;
  margin-top: 100px;
  margin-bottom: 100px;
  transform: skew(0, -7deg);
}
#signup .form a {
  color: #fff;
}
@media all and (max-width: 1180px) {
  #signup .form {
    transform: none;
    width: 100%;
    margin-top: 30px;
  }
}
#signup .form.backgrounded {
  border-radius: 10px;
  background: #f00;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.7);
  padding: 15px;
  margin-top: 80px;
  background: linear-gradient(270deg, #FF6400, #AF4BFF);
}
#signup .form input[type=text], #signup .form textarea, #signup .form select {
  display: block;
  background: #fff;
  border: none;
  padding: 15px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.7);
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  font-family: "Figtree", sans-serif;
  font-size: 18px;
  border: 5px solid #fff;
  transition: all 0.3s linear;
}
#signup .form input[type=text]:hover, #signup .form textarea:hover, #signup .form select:hover {
  border: 5px solid #AF4BFF;
  cursor: pointer;
}
#signup .form input[type=text]:focus, #signup .form textarea:focus, #signup .form select:focus {
  outline: 0;
  border: 5px solid #FF6400;
}
#signup .form input[type=text] + div,
#signup .form textarea + div,
#signup .form select + div {
  position: relative;
  top: -12px;
}
#signup .form textarea {
  height: 150px;
}
#signup .form button {
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 40px;
}

:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

.marquee {
  display: flex;
  gap: var(--gap);
  user-select: none;
  transform: skew(7deg, -7deg);
  margin-bottom: 40px;
  width: 100vw;
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}
.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}
@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}
.marquee li {
  align-items: center;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 170px;
  justify-content: center;
  list-style-type: none;
  text-align: center;
  width: 330px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}
.marquee li.text-games {
  background-color: rgba(175, 75, 255, 0.6);
}
.marquee li.text-movies {
  background-color: rgba(255, 100, 0, 0.6);
}
.marquee li h2 {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  text-transform: uppercase;
  font-size: 70px;
  font-weight: normal;
  letter-spacing: 2px;
  text-align: center;
}
.marquee li p {
  font-style: italic;
}

#pricing {
  position: relative;
  margin-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
}
@media all and (max-width: 768px) {
  #pricing {
    margin-top: 60px;
  }
}
#pricing h2 {
  font-size: 70px;
  margin-bottom: 30px;
}
@media all and (max-width: 768px) {
  #pricing h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  }
}
#pricing h2 span {
  font-size: 80px;
  text-transform: uppercase;
  -webkit-text-stroke: 7px transparent;
  background: linear-gradient(270deg, #FF6400, #FF6400);
  -webkit-background-clip: text;
  color: #fff;
}
@media all and (max-width: 768px) {
  #pricing h2 span {
    font-size: 50px;
  }
}
#pricing h3 {
  font-family: "Figtree", sans-serif;
  font-style: italic;
  font-size: 24px;
  margin-bottom: 10px;
}
#pricing p {
  color: #fff;
  font-size: 15px;
  width: 550px;
}
@media all and (max-width: 768px) {
  #pricing p {
    width: auto;
  }
}
#pricing .bundles {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 100px;
}
@media all and (max-width: 1180px) {
  #pricing .bundles {
    gap: 0;
    justify-content: space-between;
  }
}
@media all and (max-width: 820px) {
  #pricing .bundles {
    margin-top: 190px;
  }
}
@media all and (max-width: 768px) {
  #pricing .bundles {
    display: block;
  }
}
#pricing .bundles > div {
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 20%;
  text-align: center;
}
@media all and (max-width: 1180px) {
  #pricing .bundles > div {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  #pricing .bundles > div {
    width: 100%;
    margin-bottom: 50px;
  }
}
#pricing .bundles > div.movies {
  background: #AF4BFF;
}
#pricing .bundles > div.movies .price, #pricing .bundles > div.movies a {
  color: #AF4BFF;
}
#pricing .bundles > div.movies h3 {
  background: linear-gradient(to left, #AF4BFF, #AF4BFF);
  -webkit-background-clip: text;
  -webkit-text-stroke: 10px transparent;
  color: #fff;
}
#pricing .bundles > div.bundle {
  background: linear-gradient(to left, #FF6400, #AF4BFF);
  transform: translateY(-70px);
}
@media all and (max-width: 768px) {
  #pricing .bundles > div.bundle {
    transform: none;
    background: linear-gradient(to top, #FF6400, #AF4BFF);
  }
}
#pricing .bundles > div.bundle a {
  color: #fff;
}
#pricing .bundles > div.bundle .price, #pricing .bundles > div.bundle a {
  color: #fff;
  background: linear-gradient(to left, #FF6400, #AF4BFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#pricing .bundles > div.bundle h3 {
  background: linear-gradient(to left, #FF6400, #AF4BFF);
  -webkit-background-clip: text;
  -webkit-text-stroke: 10px transparent;
  color: #fff;
}
#pricing .bundles > div.games {
  background: #FF6400;
  transform: translateY(-140px);
}
@media all and (max-width: 768px) {
  #pricing .bundles > div.games {
    transform: none;
  }
}
#pricing .bundles > div.games .price, #pricing .bundles > div.games a {
  color: #FF6400;
}
#pricing .bundles > div.games h3 {
  background: linear-gradient(to left, #FF6400, #FF6400);
  -webkit-background-clip: text;
  -webkit-text-stroke: 10px transparent;
  color: #fff;
}
#pricing .bundles > div .content {
  background: #fff;
  border-radius: 10px;
  flex: 1;
  color: #171717;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
  padding-bottom: 30px;
}
#pricing .bundles > div .content h3 {
  font-family: "Bebas Neue", cursive;
  font-size: 70px;
  font-style: initial;
  text-transform: uppercase;
  margin-top: -50px;
  color: #fff;
}
@media all and (max-width: 820px) {
  #pricing .bundles > div .content h3 {
    margin-top: -60px;
    font-size: 50px;
  }
}
#pricing .bundles > div .content .note {
  font-size: 12px;
  color: #868686;
}
#pricing .bundles > div .content .price {
  font-size: 80px;
  font-family: "Bebas Neue", cursive;
  margin: 15px 0;
}
#pricing .bundles > div .content .price span {
  font-size: 30px;
}
@media all and (max-width: 820px) {
  #pricing .bundles > div .content .price span {
    font-size: 20px;
  }
}
@media all and (max-width: 820px) {
  #pricing .bundles > div .content .price {
    font-size: 40px;
  }
}
#pricing .bundles > div .content ul {
  list-style-type: none;
  text-align: center;
  font-style: italic;
}
#pricing .bundles > div .content ul li {
  margin-bottom: 10px;
}
#pricing .bundles > div .button-wrapper {
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
#pricing .bundles > div .button-wrapper:hover {
  transform: rotate(-5deg) scale(1.1);
}
#pricing .bundles > div a {
  display: block;
  font-family: "Bebas Neue", cursive;
  text-transform: uppercase;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.7);
  padding: 15px 0;
  font-size: 40px;
  text-decoration: none;
}

footer {
  background-image: url(../images/footer-bg.svg);
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  background-size: cover;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  color: #868686;
  opacity: 0.9;
}
@media all and (max-width: 1180px) {
  footer {
    margin-left: 0;
    margin-right: 0;
  }
}
@media all and (max-width: 768px) {
  footer {
    margin-left: 0;
    margin-right: 0;
  }
}
footer .flash-button {
  text-transform: uppercase;
  font-size: 30px;
  padding-left: 50px;
  padding-right: 50px;
  display: inline-block;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
@media all and (max-width: 768px) {
  footer .flash-button {
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    margin-bottom: 100px;
  }
}
footer .flash-button:hover {
  transform: rotate(-5deg) scale(1.1);
}
footer a {
  color: #868686;
  text-decoration: none;
  transition: all 0.3s linear;
}
footer a:hover {
  opacity: 0.6;
}
footer .logo {
  display: block;
  width: 150px;
  margin: auto;
}
footer .columns {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
@media all and (max-width: 768px) {
  footer .columns {
    display: block;
  }
}
footer .columns > div {
  width: 33.333333%;
  font-size: 15px;
}
@media all and (max-width: 768px) {
  footer .columns > div {
    width: auto;
  }
}
footer .columns > div h4 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  footer .columns > div h4 {
    margin-top: 40px;
  }
}
footer .copyright {
  font-size: 14px;
  width: 80%;
}
@media all and (max-width: 768px) {
  footer .copyright {
    width: auto;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
@media all and (max-width: 768px) {
  .col-6 {
    width: 100%;
  }
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.site-TVGame #signup .form {
  transform: none;
}
.site-TVGame #signup .form.backgrounded {
  padding: 0;
  background: transparent;
}
.site-TVGame .marquee svg {
  display: none;
}
.site-TVGame main h1 span,
.site-TVGame #pricing h2 span,
.site-TVGame #signup h2 span {
  -webkit-text-stroke: 9px transparent;
  padding-left: 3px;
  padding-right: 3px;
}
@media all and (max-width: 820px) {
  .site-TVGame main h1 span,
  .site-TVGame #pricing h2 span,
  .site-TVGame #signup h2 span {
    font-size: 41px;
    -webkit-text-stroke: 7px transparent;
  }
}
.site-TVGame .splatter-top,
.site-TVGame .splatter-bottom {
  display: none;
}
.site-TVGame header nav ul a {
  color: #fff;
}
.site-TVGame header nav ul a.active {
  color: #AF4BFF;
}
.site-TVGame .marquee li.text-movies {
  background: #FF6400;
}
.site-TVGame .marquee li.text-games {
  background: #AF4BFF;
}
.site-TVGame main h2, .site-TVGame main h3 {
  font-style: normal;
}
.site-TVGame #pricing .bundles > div .content {
  background: transparent;
  box-shadow: none;
  color: #fff;
}
.site-TVGame #pricing .bundles > div .price {
  color: #fff;
}
.site-TVGame #pricing .bundles > div .note {
  color: #fff;
}
.site-TVGame #pricing .bundles > div.bundle {
  background: #06192f;
}
.site-TVGame #pricing .bundles > div.bundle .price {
  color: #fff;
  background: transparent;
  -webkit-text-fill-color: initial;
}
.site-TVGame #pricing .bundles > div.bundle a {
  color: #06192f;
  background: transparent;
  -webkit-text-fill-color: initial;
}
.site-TVGame #pricing .bundles > div.bundle h3 {
  background: linear-gradient(270deg, #06192f, #06192f);
  -webkit-background-clip: text;
}
.site-TVGame footer {
  background-image: none;
  background-color: #06192f;
  opacity: 1;
  border-radius: 20px 20px 0 0;
}
.site-TVGame footer .logo {
  position: relative;
  top: -120px;
  margin-bottom: -230px;
}
@media all and (max-width: 820px) {
  .site-TVGame footer .logo {
    top: -80px;
    margin-bottom: -150px;
  }
}

.site-FunZones {
  background: #9818ff;
}
.site-FunZones main .content {
  text-align: center;
}
.site-FunZones main .content h1 {
  font-weight: 400;
}
.site-FunZones main .content h1 span {
  font-weight: 700;
}
.site-FunZones .marquee {
  transform: none;
  margin-top: 30px;
}
.site-FunZones .marquee svg {
  display: none;
}
.site-FunZones main h1 span,
.site-FunZones #pricing h2 span,
.site-FunZones #signup h2 span {
  background: transparent;
  color: #FF6400;
}
.site-FunZones .splatter-top,
.site-FunZones .splatter-bottom {
  display: none;
}
.site-FunZones #pricing p {
  font-size: 18px;
  width: 990px;
}
.site-FunZones header nav ul {
  gap: 70px;
}
.site-FunZones header nav ul a,
.site-FunZones header nav ul a.flash-button {
  color: #fff;
  font-size: 25px;
}
.site-FunZones header nav ul a.active {
  color: #FF6400;
}
.site-FunZones .marquee li {
  filter: grayscale(1);
}
.site-FunZones .marquee li h2 {
  font-weight: 700;
}
.site-FunZones .marquee li.text-movies {
  background: #FF6400;
  filter: grayscale(0);
}
.site-FunZones .marquee li.text-games {
  background: #AF4BFF;
  filter: grayscale(0);
}
.site-FunZones main h2, .site-FunZones main h3 {
  font-style: normal;
}
.site-FunZones #pricing h2 {
  font-weight: 400;
  text-align: center;
}
.site-FunZones #pricing h2 span {
  font-weight: 700;
}
.site-FunZones #pricing p {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
@media all and (max-width: 820px) {
  .site-FunZones #pricing p {
    width: auto;
  }
}
.site-FunZones #pricing .bundles {
  margin-bottom: 250px;
  margin-top: 0;
}
.site-FunZones #pricing .bundles > div {
  transform: none;
  background: transparent;
  border: 5px solid #FF6400;
  margin-top: 100px;
}
.site-FunZones #pricing .bundles > div .content {
  background: transparent;
  box-shadow: none;
  color: #fff;
}
.site-FunZones #pricing .bundles > div .price {
  color: #fff;
}
.site-FunZones #pricing .bundles > div .note {
  color: #fff;
}
.site-FunZones #pricing .bundles > div .price {
  color: #fff;
  background: transparent;
  -webkit-text-fill-color: initial;
  font-weight: 700;
}
.site-FunZones #pricing .bundles > div .button-wrapper {
  margin-bottom: -60px;
}
.site-FunZones #pricing .bundles > div a {
  color: #06192f;
  background: transparent;
  -webkit-text-fill-color: initial;
}
.site-FunZones #pricing .bundles > div h3 {
  background: #9818ff;
  -webkit-background-clip: initial;
  font-weight: 700;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
.site-FunZones footer {
  border-radius: 20px 20px 0 0;
  opacity: 1;
  background: transparent;
  border: 5px solid #FF6400;
  border-bottom: none;
}
.site-FunZones footer .logo {
  margin-bottom: -130px;
  position: relative;
  top: -65px;
  background: #9818ff;
}
.site-FunZones .flash-button {
  background: #FF6400;
  border-radius: 0;
}
.site-FunZones #signup .form {
  transform: none;
}
.site-FunZones #signup .form input, .site-FunZones #signup .form textarea {
  border-radius: 0;
}
.site-FunZones #signup p {
  margin: auto;
}
@media all and (max-width: 820px) {
  .site-FunZones #signup p {
    width: auto;
  }
}

.site-FunVision {
  background: #2a004b;
}
.site-FunVision #home .content {
  margin-bottom: 100px;
  margin-top: 100px;
  text-align: center;
}
.site-FunVision .marquee {
  transform: none;
  position: relative;
}
.site-FunVision .marquee li {
  filter: grayscale(1);
  box-shadow: none;
  opacity: 0.6;
}
.site-FunVision .marquee li.text-games, .site-FunVision .marquee li.text-movies {
  filter: none;
  opacity: 1;
}
.site-FunVision .marquee li p {
  font-style: normal;
}
.site-FunVision .marquee svg {
  display: none;
}
.site-FunVision .marquee:before {
  background: linear-gradient(-270deg, #2a004b, transparent);
  content: "";
  width: 200px;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}
@media all and (max-width: 820px) {
  .site-FunVision .marquee:before {
    width: 100px;
  }
}
.site-FunVision .marquee:after {
  background: linear-gradient(-270deg, transparent, #2a004b);
  content: "";
  width: 200px;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
}
@media all and (max-width: 820px) {
  .site-FunVision .marquee:after {
    width: 100px;
  }
}
.site-FunVision main h1 span,
.site-FunVision #pricing h2 span,
.site-FunVision #signup h2 span {
  background: transparent;
  color: #FF6400;
}
.site-FunVision .splatter-top,
.site-FunVision .splatter-bottom {
  display: none;
}
.site-FunVision header nav ul {
  gap: 70px;
}
.site-FunVision header nav ul a,
.site-FunVision header nav ul a.flash-button {
  color: #fff;
  font-size: 25px;
}
.site-FunVision header nav ul a.active {
  color: #FF6400;
}
.site-FunVision .marquee li {
  border-radius: 0;
}
.site-FunVision .marquee li.text-movies {
  background: #FF6400;
}
.site-FunVision .marquee li.text-games {
  background: #AF4BFF;
}
.site-FunVision main h2, .site-FunVision main h3 {
  font-style: normal;
}
.site-FunVision #pricing {
  margin-top: 0;
}
.site-FunVision #pricing .bundles {
  margin-bottom: 200px;
}
@media all and (max-width: 820px) {
  .site-FunVision #pricing .bundles {
    margin-top: 0;
  }
}
.site-FunVision #pricing .bundles > div {
  border-radius: 0;
}
@media all and (max-width: 820px) {
  .site-FunVision #pricing .bundles > div {
    margin-top: 100px;
  }
}
.site-FunVision #pricing .bundles > div .content {
  background: transparent;
  box-shadow: none;
  color: #fff;
}
.site-FunVision #pricing .bundles > div .price {
  color: #fff;
}
.site-FunVision #pricing .bundles > div .note {
  color: #fff;
}
.site-FunVision #pricing .bundles > div .button-wrapper {
  border-radius: 0;
}
.site-FunVision #pricing .bundles > div.movies {
  transform: translateY(-140px);
}
@media all and (max-width: 820px) {
  .site-FunVision #pricing .bundles > div.movies {
    transform: none;
  }
}
.site-FunVision #pricing .bundles > div.bundle {
  background: #06192f;
  transform: translateY(-70px);
}
@media all and (max-width: 820px) {
  .site-FunVision #pricing .bundles > div.bundle {
    transform: none;
  }
}
.site-FunVision #pricing .bundles > div.bundle .price {
  color: #fff;
  background: transparent;
  -webkit-text-fill-color: initial;
}
.site-FunVision #pricing .bundles > div.bundle a {
  color: #06192f;
  background: transparent;
  -webkit-text-fill-color: initial;
}
.site-FunVision #pricing .bundles > div.bundle h3 {
  background: linear-gradient(270deg, #06192f, #06192f);
  -webkit-background-clip: text;
}
.site-FunVision #pricing .bundles > div.games {
  transform: none;
}
.site-FunVision #pricing h2 {
  text-align: right;
}
@media all and (max-width: 820px) {
  .site-FunVision #pricing h2 {
    text-align: left;
  }
}
.site-FunVision #pricing p {
  font-size: 18px;
  width: 690px;
  margin-left: auto;
  text-align: right;
}
@media all and (max-width: 820px) {
  .site-FunVision #pricing p {
    width: auto;
    text-align: left;
  }
}
.site-FunVision footer {
  background-image: none;
  background-color: #06192f;
  opacity: 1;
  border-radius: 20px 20px 0 0;
}
.site-FunVision footer .logo {
  margin-bottom: -70px;
  position: relative;
  top: -15px;
}
.site-FunVision .flash-button {
  background: #FF6400;
  border-radius: 0;
}
.site-FunVision #signup .form {
  transform: none;
}
.site-FunVision #signup .form input, .site-FunVision #signup .form textarea {
  border-radius: 0;
}

.site-PlayStream {
  background: #2a004b;
}
.site-PlayStream .marquee svg:nth-child(even) path {
  fill: #fff;
}
.site-PlayStream .marquee svg:nth-child(odd) path {
  fill: #AF4BFF;
}
.site-PlayStream main h1 span,
.site-PlayStream #pricing h2 span,
.site-PlayStream #signup h2 span {
  background: transparent;
  color: #FF6400;
}
.site-PlayStream .splatter-top,
.site-PlayStream .splatter-bottom {
  display: none;
}
.site-PlayStream #pricing p {
  font-size: 18px;
  width: 690px;
}
.site-PlayStream header nav ul {
  gap: 70px;
}
.site-PlayStream header nav ul a,
.site-PlayStream header nav ul a.flash-button {
  color: #fff;
  font-size: 25px;
}
.site-PlayStream header nav ul a.active {
  color: #FF6400;
}
.site-PlayStream .marquee li {
  border-radius: 0;
}
.site-PlayStream .marquee li.text-movies {
  background: #FF6400;
}
.site-PlayStream .marquee li.text-games {
  background: #AF4BFF;
}
.site-PlayStream main h2, .site-PlayStream main h3 {
  font-style: normal;
}
.site-PlayStream #pricing .bundles > div {
  border-radius: 0;
}
.site-PlayStream #pricing .bundles > div .content {
  background: transparent;
  box-shadow: none;
  color: #fff;
}
.site-PlayStream #pricing .bundles > div .price {
  color: #fff;
}
.site-PlayStream #pricing .bundles > div .note {
  color: #fff;
}
.site-PlayStream #pricing .bundles > div .button-wrapper {
  border-radius: 0;
}
.site-PlayStream #pricing .bundles > div.bundle {
  background: #06192f;
}
.site-PlayStream #pricing .bundles > div.bundle .price {
  color: #fff;
  background: transparent;
  -webkit-text-fill-color: initial;
}
.site-PlayStream #pricing .bundles > div.bundle a {
  color: #06192f;
  background: transparent;
  -webkit-text-fill-color: initial;
}
.site-PlayStream #pricing .bundles > div.bundle h3 {
  background: linear-gradient(270deg, #06192f, #06192f);
  -webkit-background-clip: text;
}
.site-PlayStream footer {
  background-image: none;
  background-color: #06192f;
  opacity: 1;
  border-radius: 20px 20px 0 0;
}
.site-PlayStream footer .logo {
  margin-bottom: -70px;
  position: relative;
  top: -15px;
}
.site-PlayStream .flash-button {
  background: #FF6400;
  border-radius: 0;
}
.site-PlayStream #signup .form input, .site-PlayStream #signup .form textarea {
  border-radius: 0;
}
.site-PlayStream .marquee {
  margin-bottom: 0;
  gap: 0;
}
.site-PlayStream .marquee__group {
  gap: 0;
}

.form-check a {
  color: #FF6400 !important;
}

#contactForm {
  transition: all 200ms linear;
  position: relative;
}
#contactForm .icon {
  display: none;
  position: absolute;
  width: 128px;
  height: 43px;
  left: 50%;
  top: 50%;
  margin-left: -64px;
  margin-top: -21px;
}
#contactForm.loading {
  opacity: 0.3;
}
#contactForm.loading .icon {
  display: block;
}